import { League } from '@/GeneratedTypes/League'

export function setInformationForNewLeague(league: League) {
  if (!league.upwardLeagueID) {
    league.programMissionObjective = 'goal'

    league.leagueName = !league.leagueName.includes('~')
      ? `${league.leagueName} ~ ${new Date().toDateString()}`
      : league.leagueName

    league.practiceNights = [
      { endTime: '23:59:59', startTime: '00:00:01', typeDayID: 'Saturday' },
      { endTime: '23:59:59', startTime: '00:00:01', typeDayID: 'Sunday' },
      { endTime: '23:59:59', startTime: '00:00:01', typeDayID: 'Monday' },
    ]
  }
}
